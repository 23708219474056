import React, { useEffect, useState } from "react";
import Firstslider from "../Menhome/Firstslider";
import Company from "../Menhome/Company";
import Safe from "../Menhome/Safe";
import Video from "../Menhome/Video";
// import Calcuter from "../Menhome/Calcuter";
import Testimonials from "../Menhome/Testimonials";
import Counter from "../Menhome/Counter";
import Benefit from "../Menhome/Benefit";
import Homefaq from "../Menhome/Homefaq";
import Emicalculator from "../Emicalculator/Emicalculator";
import Service from "../Services/Service/Main";
import First from "../Services/Menservise/First";
import RupeeSpinner from "../Spinners/Spinners";
import New from "../Credit/Menaudit/New";

const Main = () => {
  return (
    <>
      <Firstslider />
      <New />
      <br></br>
      <br></br>

      <Company />
      <First />
      <Emicalculator />
      <Homefaq />
      <Safe />
      <Video />
      <Testimonials />
      <Counter />
      <Benefit />
    </>
  );
};

export default Main;
